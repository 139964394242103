<template>
  <div>
    <b-card v-if="visiteData">
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="8"
          md="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4
                  v-if="visiteData.point_de_vente"
                  class="mb-0"
                >
                  Visite du {{ visiteData.date }} chez {{ visiteData.point_de_vente}}
                  [ Téléphone : {{ visiteData.contact }} ]
                </h4>
                <h4
                  v-if="!visiteData.point_de_vente"
                  class="mb-0"
                >
                  Chargement....
                </h4>
                <span class="card-text">{{ visiteData.telephone }}</span>
              </div>
              <div>
                <table
                  v-if="visiteData.commercial"
                  class="mt-2 mt-xl-0 w-100"
                >
                  <tr>
                    <th class="pb-50">
                      <img
                        width="30px"
                        :src="imageCommerciaux"
                        alt="Description de l'image"
                      >
                      <span class="font-weight-bold ml-1">Commercial</span>
                      <b-badge variant="light-success">
                        {{ visiteData.commercial }}
                      </b-badge>
                    </th>
                  </tr>
<!--                  <tr>-->
<!--                    <th class="pb-50">-->
<!--                      <img-->
<!--                        width="30px"-->
<!--                        :src="imageTourners"-->
<!--                        alt="Description de l'image"-->
<!--                      >-->
<!--                      <span class="font-weight-bold ml-1">Tournée </span>-->
<!--                      <b-badge variant="light-success">-->
<!--                        {{ visiteData.tourner_r.nom }}-->
<!--                      </b-badge>-->
<!--                    </th>-->
<!--                  </tr>-->
                </table>
              </div>
            </div>
          </div>

          <!-- User Stats -->
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          v-if="visiteData.latitude && visiteData.longitude"
          cols="4"
          md="6"
        >
          <l-map
            style="height: 200px; border: #2a6f39 1px solid;"
            :zoom="zoom"
            :center="[visiteData.latitude, visiteData.longitude]"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
            />
            <l-marker
              :lat-lng="[visiteData.latitude, visiteData.longitude]"
              :icon="icon"
            >
              <l-popup :content="visiteData.nom" />
            </l-marker>
            <l-marker
              v-if="visiteData.latitude && visiteData.longitude"
              :lat-lng="[visiteData.latitude, visiteData.longitude]"
              :icon="icon2"
            >
              <l-popup :content="visiteData.commercial" />
            </l-marker>

          </l-map>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-row>
          <b-col md="12">
            <b-card>
              <h2>Informations PDV</h2>
              <hr>
              <strong>
                PLV Extérieur :
                <b-badge
                  v-if="visiteData.plv_exterieur"
                  variant="light-success"
                >Oui</b-badge>
                <b-badge
                  v-if="!visiteData.plv_exterieur"
                  variant="light-danger"
                >Non</b-badge>
              </strong>
              <br>
              <strong>
                PLV Intérieur :
                <b-badge
                  v-if="visiteData.plv_interieur"
                  variant="light-success"
                >Oui</b-badge>
                <b-badge
                  v-if="!visiteData.plv_interieur"
                  variant="light-danger"
                >Non</b-badge>
              </strong>
            </b-card>
          </b-col>
          <b-col md="12">
            <b-card>
              <h2>Actions </h2>
              <hr>
              <div
                v-for="item in visiteDataAction"
                :key="item.id"
              >
                <b-row>
                  <b-col md="10">
                    <div>{{ item.action_r.nom }}</div>
                  </b-col>
                  <b-col>
                    <b-badge
                      v-if="item.realise"
                      variant="light-success"
                    >Oui </b-badge>
                    <b-badge
                      v-if="!item.realise"
                      variant="light-danger"
                    >Non </b-badge>
                  </b-col>
                </b-row>
                <hr>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row>
          <b-col md="12">
            <b-card>
              <h2>Disponibilité des produits AFRIDIA</h2>
              <hr>
              <div
                v-for="item in visiteDataJusAfridia"
                :key="item.id"
              >
                <b-row>
                  <b-col md="4">
                    <img
                      :src="'data:image/png;base64,'+ item.produit_r.image"
                      class="rounded mr-50"
                      height="100"
                      width="100"
                      :alt="item.produit_r.nom_produit"
                    >
                  </b-col>
                  <b-col md="8">
                    <div>{{ item.produit_r.nom_produit }}
                      <b-badge
                        v-if="item.disponible"
                        variant="light-success"
                      >Disponible </b-badge>
                      <b-badge
                        v-if="!item.disponible"
                        variant="light-danger"
                      >Non disponible </b-badge>
                    </div>
                    <div style="color: #2a6f39">
                      Quantité : {{ item.quantity }}
                    </div>
                    <div style="color: #1ab7ea">
                      Prix : {{ item.prix }} FCFA
                    </div>
                  </b-col>
                </b-row>
                <hr>
              </div>

            </b-card>
          </b-col>
          <b-col md="12">
            <b-card>
              <h2>Disponibilité des produits concurrents</h2>
              <hr>
              <b-row
                v-for="item in visiteDataJusConcurrent"
                :key="item.id"
              >
                <b-col md="4">
                  <img
                    :src="'data:image/png;base64,'+ item.produit_r.image"
                    class="rounded mr-50"
                    height="100"
                    width="100"
                    :alt="item.produit_r.nom_produit"
                  >
                </b-col>
                <b-col md="8">
                  <div>{{ item.produit_r.nom_produit }}
                    <b-badge
                      v-if="item.disponible"
                      variant="light-success"
                    >Disponible</b-badge>
                    <b-badge
                      v-if="!item.disponible"
                      variant="light-danger"
                    >Non disponible</b-badge>
                  </div>
                  <div style="color: #2a6f39">
                    Quantité : {{ item.quantity }}
                  </div>
                  <div style="color: #1ab7ea">
                    Prix : {{ item.prix }} FCFA
                  </div>
                  <hr>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BBadge, BCard, BCol, BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { $themeColors } from '@themeConfig'
import { API_URL } from '@/helpers/global-scops'
import LMap from 'vue2-leaflet/src/components/LMap.vue'
import LTileLayer from 'vue2-leaflet/src/components/LTileLayer.vue'
import LMarker from 'vue2-leaflet/src/components/LMarker.vue'
import LPopup from 'vue2-leaflet/src/components/LPopup.vue'
import { icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

const $earningsStrokeColor2 = 'rgb(3,147,255)'
const $earningsStrokeColor3 = 'rgba(3,147,255,0.28)'

export default {
  name: 'MainVue',
  components: {
    LPopup,
    LMarker,
    LTileLayer,
    LMap,
    BBadge,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 11,
      center: [12.6494, -8.0005],
      markerLatLng: [12.6494, -8.0005],
      icon: icon({
        iconUrl: 'markers/marker_green.png',
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      icon2: icon({
        iconUrl: 'markers/marker.png',
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Tournées',
                    formatter() {
                      return '70%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      visiteData: {},
      visiteDataJusAfridia: {},
      visiteDataJusConcurrent: {},
      visiteDataAction: {},
      // eslint-disable-next-line global-require
      imageDistributeurs: require('@/assets/images/icons/box.png'),
      // eslint-disable-next-line global-require
      imageCommerciaux: require('@/assets/images/icons/team.png'),
      // eslint-disable-next-line global-require
      imageResponsable: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageTourners: require('@/assets/images/icons/tourners.png'),
      // eslint-disable-next-line global-require
      imagePDV: require('@/assets/images/icons/store.png'),
      userData: {},
    }
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  created() {
    // this.getVisiteData()
    this.getVisiteDetails()
  },
  methods: {
    getVisiteData() {
      this.$http.get(`${API_URL}get-opvisitepdv/${this.$route.params.id}/`)
        .then(response => {
          this.visiteData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getVisiteDetails() {
      this.$http.get(`${API_URL}get-opvisitepdv/${this.$route.params.id}/`)
        .then(response => {
          this.visiteData = response.data
          console.log(this.visiteData)
        })
        .catch(error => {
          console.log(error)
        })

      this.$http.get(`${API_URL}get-opvisitepdv-actions/${this.$route.params.id}/`)
        .then(response => {
          this.visiteDataAction = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.$http.get(`${API_URL}get-opvisitepdv-jusafridia/${this.$route.params.id}/`)
        .then(response => {
          this.visiteDataJusAfridia = response.data
        })
        .catch(error => {
          console.log(error)
        })

      this.$http.get(`${API_URL}get-opvisitepdv-jusconcurrent/${this.$route.params.id}/`)
        .then(response => {
          this.visiteDataJusConcurrent = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
